<template>
    <tr>
        <td class="w4" />
        <td class="w4">
            <styled-tooltip
                class="object-name-inline_name"
                :max-width="300"
                position="top"
                open-delay="0"
                close-delay="0"
                :lazy="false">
                <styled-checkbox
                    class="small"
                    :checked="selected"
                    :row="false"
                    :disabled="pinterestDynamic"
                    @change="updateSelected" />
                <template
                    v-if="pinterestDynamic"
                    #content>
                    Pinterest does not allow platforms like BuyerBridge to view dynamic ads outside of the Pinterest ads
                    manager.
                    Use the menu on this adset and click "View in Ads Manager" to view the dynamic ads associated with
                    this adset.
                </template>
            </styled-tooltip>
        </td>
        <td class="w20">
            <div
                class="switcher-wrapper"
                :class="{adsTab: type === 'ads'}">
                <v-switch
                    v-if="!switchLoading"
                    class="switcher mr-2"
                    color="primary"
                    :input-value="itemActiveStatus"
                    :disabled="switchDisabled"
                    @change="switchItem" />
                <template v-if="switchLoading">
                    <action-button
                        class="loading mr-2"
                        icon="download"
                        :loading="switchLoading" />
                </template>
                <span
                    v-if="type === 'ads' && !loadingLazy"
                    class="image-preview mr-2"
                    :style="{backgroundImage: `url(${getMediaUrl})`}" />
                <styled-loader
                    v-if="type === 'ads' && loadingLazy"
                    class="mr-3"
                    size="18" />

                <div class="w100">
                    <div class="object-name-inline">
                        <styled-tooltip
                            class="object-name-inline_name"
                            :max-width="300"
                            position="top"
                            open-delay="0"
                            close-delay="0"
                            :lazy="false">
                            <styled-loader
                                v-if="nameLoading"
                                class="name-loader"
                                :size="15"
                                :loading="nameLoading" />
                            <span
                                v-if="!nameLoading"
                                :class="{link: type !== 'ads'}"
                                @click="$emit('open', item)">
                                {{ truncateString(platformObject.name, 64) }}
                            </span>
                            <template #content>
                                {{ item.name }}
                            </template>
                        </styled-tooltip>

                        <v-edit-dialog
                            class="object-name-inline_edit"
                            large
                            @open="open"
                            @save="changeName"
                            @cancel="revertNameChange">
                            <icon
                                name="edit"
                                size="15px" />
                            <template #input>
                                <v-text-field
                                    v-model="item.name"
                                    :disabled="nameLoading"
                                    label="Edit"
                                    single-line />
                            </template>
                        </v-edit-dialog>

                        <styled-tooltip
                            class="object-name-inline_copy"
                            :max-width="300"
                            :lazy="false"
                            position="top">
                            <span class="mt-2">
                                <action-button
                                    v-clipboard:copy="item.external_id"
                                    v-clipboard:success="onIdCopy"
                                    icon="copy"
                                    size="15"
                                    class="flex" />
                            </span>
                            <template #content>
                                Copy ID {{ item.external_id }}
                            </template>
                            <div
                                v-if="pinterestDynamic"
                                class="badge">
                                <span>DYNAMIC</span>
                            </div>
                        </styled-tooltip>
                    </div>
                </div>
            </div>
        </td>
        <td class="centered w20">
            <span
                class="circle small"
                :class="{'active': itemActiveStatus}" />
            {{ item.status }}
        </td>
        <td
            v-if="type === 'ads'"
            class="w10">
            <styled-tooltip
                :max-width="300"
                position="top"
                :lazy="false">
                <span
                    v-if="!nameLoading">
                    {{ truncateString(platformObject.ad_set_name, 43) }}
                </span>
                <template #content>
                    {{ item.ad_set_name }}
                </template>
            </styled-tooltip>
        </td>
        <td
            v-else
            class="centered w10 budget-cell">
            <empty-field v-if="running" />
            <v-edit-dialog
                v-else-if="type === getBudgetManagingLevel()"
                large
                @open="open"
                @save="changeBudget"
                @cancel="revertBudgetChange">
                <span v-if="!budgetLoading">
                    <span>$</span>
                    <span>{{ getBudgetValue }}</span>
                    <span>/{{ getBudgetType }}</span>
                </span>
                <span v-else>
                    <action-button
                        class="loading"
                        icon="download"
                        :loading="budgetLoading" />
                </span>
                <icon
                    name="edit"
                    size="15px" />

                <span
                    v-if="budgetError"
                    class="budget-error-message">{{ budgetError }}</span>
                <template #input>
                    <v-text-field
                        v-model="item.budget[getBudgetKey]"
                        label="Edit"
                        :disabled="budgetLoading"
                        single-line
                        prefix="$"
                        type="number"
                        :suffix="`/${getBudgetType}`" />

                    <span
                        v-if="budgetError"
                        class="budget-error-message">{{ budgetError }}</span>
                </template>
            </v-edit-dialog>
            <span v-else>
                <span v-if="getBudgetManagingLevel() === 'adsets'">
                    Managed at Ad-Set Level
                </span>
                <span v-else>
                    Managed at Campaign Level
                </span>
            </span>
        </td>
        <td class="centered w8">
            <loading-shimmer v-if="loadingLazy" />
            <empty-field v-else-if="running" />
            <span v-else>
                {{ item.stats ? formatNumber(item.stats.spend, 'currency') : "$0.00" }}
            </span>
        </td>
        <td class="centered w8">
            <loading-shimmer v-if="loadingLazy" />
            <empty-field v-else-if="running" />
            <div
                v-else
                class="divided-wrapper">
                <span class="divided">{{ item.stats ? formatNumber(item.stats.impressions, 'number') : 0 }}</span>
                <div>{{ item.stats ? formatNumber(item.stats.cpm, 'currency') : "$0.00" }}</div>
            </div>
        </td>
        <td class="centered w8">
            <loading-shimmer v-if="loadingLazy" />
            <empty-field v-else-if="running" />
            <div
                v-else
                class="divided-wrapper">
                <span class="divided">{{ item.stats ? formatNumber(item.stats.clicks, 'number') : 0 }}</span>
                <div>{{ item.stats ? formatNumber(item.stats.cost_per_click, 'currency') : "$0.00" }}</div>
            </div>
        </td>
        <td class="centered w8">
            <loading-shimmer v-if="loadingLazy" />
            <empty-field v-else-if="running" />
            <div
                v-else
                class="divided-wrapper">
                <span class="divided">{{ item.stats ? formatNumber(item.stats.leads, 'number') : 0 }}</span>
                <div>{{ item.stats ? formatNumber(item.stats.cost_per_lead, 'currency') : "$0.00" }}</div>
            </div>
        </td>
        <td class="centered w8">
            <loading-shimmer v-if="loadingLazy" />
            <empty-field v-else-if="running" />
            <div
                v-else
                class="divided-wrapper">
                <span class="divided">{{ item.stats ? formatNumber(item.stats.sales, 'number') : 0 }}</span>
                <div>{{ item.stats ? formatNumber(item.stats.cost_per_sale, 'currency') : "$0.00" }}</div>
            </div>
        </td>
        <td class="centered w2">
            <vertical-menu>
                <a
                    :href="platformAdManagerLink.url"
                    target="_blank">
                    {{ platformAdManagerLink.text }}
                </a>
                <div
                    v-if="isCampaignRow && channelName === PLATFORM_FACEBOOK"
                    class="separator" />
                <a
                    v-if="isCampaignRow && channelName === PLATFORM_FACEBOOK"
                    class="menu-link with-line"
                    @click="tagAsBuyerbridge">
                    {{ isTaggedAsBuyerBridge ? 'Un-tag' : 'Tag' }} as BuyerBridge
                </a>
                <div
                    v-if="channelName === PLATFORM_PINTEREST"
                    class="separator" />
                <a
                    v-if="channelName === PLATFORM_PINTEREST"
                    :href="dynamicProductAdsLink"
                    target="_blank"
                    class="dynamic-ads-link">
                    View Pinterest Dynamic Product Ads
                </a>
            </vertical-menu>
        </td>
    </tr>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Icon from '@/components/globals/Icon';
import EmptyField from '@/components/globals/EmptyField';
import VerticalMenu from '@/components/globals/VerticalMenu';
import StyledLoader from '@/components/globals/StyledLoader';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import ActionButton from '@/components/globals/ActionButton';
import StyledTooltip from '@/components/globals/StyledTooltip';
import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';
import LoadingShimmer from '@/components/globals/LoadingShimmer';
import {
    getPlatformAdManagerObjectUrl,
    URL_TYPE_ADSET_EDIT,
    URL_TYPE_AD_EDIT,
    URL_TYPE_CAMPAIGN_EDIT
} from '@/helpers/platformUrls';
import { getDealerPlatformExternalIds } from '../onboarding/channel-setup/channels';
import { PLATFORM_TIKTOK, PLATFORM_PINTEREST, PLATFORM_FACEBOOK } from '@/helpers/globals';

export default {
    components: {
        Icon,
        EmptyField,
        VerticalMenu,
        StyledCheckbox,
        ActionButton,
        StyledTooltip,
        StyledLoader,
        LoadingShimmer
    },
    props: {
        platformObject: {
            type: Object,
            required: true
        },
        platformObjectSelectedStatus: {
            type: Boolean,
            default: false
        },
        channelName: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'campaigns'
        },
        loadingLazy: {
            type: Boolean,
            default: false
        },
        running: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            switchDisabled: false,
            switchLoading: false,
            nameDisabled: false,
            name: null,
            nameLoading: false,
            budget: null,
            budgetDisabled: false,
            budgetLoading: false,
            budgetError: null,
            item: {},
            PLATFORM_TIKTOK,
            PLATFORM_PINTEREST,
            PLATFORM_FACEBOOK
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        dynamicProductAdsLink() {
            const { adAccountId } = getDealerPlatformExternalIds(this.currentDealer, this.channelName);

            return `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/productgroups`;
        },
        platformAdManagerLink() {

            const {
                adAccountId,
                businessManagerId,
            } = getDealerPlatformExternalIds(this.currentDealer, this.channelName);

            switch (this.type) {
                case 'campaigns':
                    return {
                        text: 'View Campaign',
                        url: getPlatformAdManagerObjectUrl({
                            adAccountId,
                            businessManagerId,
                            platform: this.channelName,
                            type: URL_TYPE_CAMPAIGN_EDIT,
                            objectIds: [this.platformObject.external_id]
                        })
                    };
                case 'adsets':
                    return {
                        text: 'View Ad Set',
                        url: getPlatformAdManagerObjectUrl({
                            adAccountId,
                            businessManagerId,
                            platform: this.channelName,
                            type: URL_TYPE_ADSET_EDIT,
                            objectIds: [this.platformObject.external_id]
                        })
                    };
                case 'ads':
                    return {
                        text: 'View Ad',
                        url: getPlatformAdManagerObjectUrl({
                            adAccountId,
                            businessManagerId,
                            platform: this.channelName,
                            type: URL_TYPE_AD_EDIT,
                            objectIds: [this.platformObject.external_id]
                        })
                    };
            }

            return {};
        },
        itemActiveStatus() {
            return this.item.status === 'ACTIVE';
        },
        getMediaUrl() {
            if (this.item.media_items?.[0]?.thumbnail_url &&
                this.item.media_items?.[0]?.media_type === 'VIDEO') {
                return this.item.media_items[0].thumbnail_url;
            }

            if (this.item.media_items?.[0]?.media_url) {
                return this.item.media_items[0].media_url;
            }

            return '/img/campaign-management-ad-placeholder.png';
        },
        getBudgetValue() {
            if (this.item.budget && this.item.budget[this.getBudgetKey]) {
                return parseFloat(this.platformObject.budget[this.getBudgetKey]).toFixed(2);
            }

            return 0.00;
        },
        getBudgetKey() {
            if (this.item.budget.budget_type === 'LIFETIME') {
                return 'lifetime_budget';
            }

            return 'daily_budget';
        },
        getBudgetType() {
            return this.item?.budget?.lifetime_budget ? 'lifetime' : 'daily';
        },
        pinterestDynamic() {
            return this.channelName === PLATFORM_PINTEREST && this.item?.is_dynamic;
        },
        isCampaignRow() {
            return this.type === 'campaigns';
        },
        isTaggedAsBuyerBridge() {
            if (!this.item) {
                return false;
            }

            return this.item?.labels?.find(label => label?.name === 'buyerbridge');
        }
    },
    watch: {
        platformObject: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (Object.keys(newVal).length) {
                    this.item = { ...newVal };
                }

            },
        }
    },
    methods: {
        ...mapActions([
            'updateDealerCampaign',
            'updateDealerAdSet',
            'updateDealerAd'
        ]),
        updateSelected(event) {
            this.$emit('select', event.target.checked);
        },
        open() {
            this.$emit('clear-filter');
        },
        onNameUpdateComplete(updatedName) {
            this.item.name = updatedName;
        },
        getBudgetManagingLevel() {
            if (!this.item?.budget?.campaign_budget_optimization) {
                return 'adsets';
            }
            return 'campaigns';
        },
        async switchItem() {
            this.switchDisabled = true;
            this.switchLoading = true;

            const params = {
                status: this.item.status === 'ACTIVE' ? 'PAUSED' : 'ACTIVE'
            };

            await this.update(params);

            this.switchDisabled = false;
            this.switchLoading = false;
        },
        async changeName() {
            this.nameDisabled = true;
            this.nameLoading = true;

            const params = {
                name: this.item.name
            };

            await this.update(params);

            this.nameDisabled = false;
            this.nameLoading = false;
        },
        revertNameChange() {
            this.item.name = this.platformObject.name;
        },
        revertBudgetChange() {
            this.item.budget.daily_budget = this.platformObject.budget.daily_budget;
        },
        async changeBudget() {
            this.budgetDisabled = true;
            this.budgetLoading = true;
            this.budgetError = '';

            // Validate budget
            const payload = {
                dealerId: this.currentDealer.id,
                platform: this.channelName,
                params: {
                    level: this.getBudgetManagingLevel(),
                    [this.getBudgetKey]: this.item.budget[this.getBudgetKey]
                }
            };

            this.getBudgetManagingLevel() === 'adsets' ? payload.params.adSetId = this.item.external_id : payload.params.campaignId = this.item.external_id;

            if (this.channelName === PLATFORM_PINTEREST) {
                const params = {
                    'budget': {
                        [this.getBudgetKey]: this.item.budget[this.getBudgetKey]
                    }
                };

                await this.update(params);
            } else {
                const validationResponse = await this.$apiRepository.getDealerPlatformBudgetValidation(payload);

                if (!validationResponse.data.error) {
                    const params = {
                        'budget': {
                            [this.getBudgetKey]: this.item.budget[this.getBudgetKey]
                        }
                    };

                    await this.update(params);
                } else {
                    this.budgetError = validationResponse.data.message;
                    this.$flash(validationResponse.data.message, 'red');
                    this.revertBudgetChange();
                }

            }

            this.budgetDisabled = false;
            this.budgetLoading = false;
        },
        async update(params) {
            try {
                const payload = {
                    platform: this.channelName,
                    id: this.item.external_id,
                    params
                };

                const map = {
                    campaigns: this.updateDealerCampaign,
                    adsets: this.updateDealerAdset,
                    ads: this.updateDealerAd
                };

                await map[this.type].call(null, payload);
            } catch (error) {
                this.item = this.platformObject;
                this.$flash(`There was an error updating this ${this.type}.  Please try again or contact support.`, 'red');
                console.error(`Error updating ${this.type}`, error.response || error);
            }
        },
        async updateDealerCampaign({ platform, id, params }) {
            const payload = {
                dealerId: this.currentDealer.id,
                platform,
                campaignId: id,
                params
            };

            const response = await this.$apiRepository.updateDealerPlatformCampaign(payload);
            this.$emit('update-campaign', response.data);
        },
        async updateDealerAdset({ platform, id, params }) {
            const payload = {
                dealerId: this.currentDealer.id,
                platform,
                adSetId: id,
                params
            };

            const response = await this.$apiRepository.updateDealerPlatformAdSet(payload);
            this.$emit('update-ad-set', response.data);
        },
        async updateDealerAd({ platform, id, params }) {
            const payload = {
                dealerId: this.currentDealer.id,
                platform,
                adId: id,
                params
            };

            const response = await this.$apiRepository.updateDealerPlatformAd(payload);
            this.$emit('update-ad', response.data);
        },
        truncateString(str, num) {
            if (str && str.length > num) {
                return str.slice(0, num) + '...';
            } else {
                return str;
            }
        },
        formatNumber(number, type) {
            const formats = getNumberFormats();

            if (type === 'currency'
                && (!number || number === 0.0)) {
                number = 0.00;
            }

            return numeral(number).format(formats[type]);
        },
        onIdCopy() {
            this.$flash('ID has been copied to clipboard!', 'green');
        },
        async tagAsBuyerbridge() {
            try {
                const buyerbridgeAdLabel = [{ name: 'buyerbridge' }];

                const labels = !this.item?.labels
                    ? buyerbridgeAdLabel
                    : this.isTaggedAsBuyerBridge
                        ? this.item?.labels?.filter(adlabel => adlabel?.name !== 'buyerbridge')
                        : [...this.item.labels, ...buyerbridgeAdLabel];

                await this.update({
                    labels
                });

                this.item.labels = labels;
            } catch (e) {
                this.item = this.platformObject;
                this.$flash('Error, please contact support in case it persists.', 'red');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.name-loader {
    vertical-align: middle;
    margin-right: 10px;
}

.budget-error-message {
    display: block;
    color: $error-500;
    font-size: 12px;
}

.badge {
    display: inline;
    background-color: grey;
    border-radius: 2px;
    font-weight: 600;
    font-size: 8px;
    width: 45px;
    text-align: center;
    padding: 0.2em 0.3em;

    span {
        color: $white
    }
}

.dynamic-ads-link {
    width: 250px;
}

.separator {
    border-top: 1px solid lightgray;
    margin-top: 10px;
    padding-bottom: 10px;
}
</style>
